import axios from '@axios'
import $http from '@/libs/http'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchWalletStatus() {
      return new Promise((resolve, reject) => {
        $http.get('/api/v1/admin/getWalletStatus')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPurchaseList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        // /api/v1/transfer/list
        $http.post('/api/v2/admin/user/purchase_list', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
