<template>
  <b-card
    no-body
    class="withdraw"
  >
    <div class="m-2">
      <b-form @submit.prevent>
        <b-row>
          <b-col md="4">
            <label
              class="mr-sm-1"
              for="inline-form-custom-select-pref"
            >Purchase Status:</label>
            <v-select
              v-model="purchaseStatus"
              :options="purchaseStatusOption"
              class="invoice-filter-select mb-1 mr-sm-1 no-clear-select"
              placeholder="All"
              label="title"
            />
          </b-col>
          <b-col md="4">
            <label for="inline-form-custom-select-pref">Time:</label>
            <b-input-group>
              <b-input-group-prepend>
                <b-dropdown
                  :text="timeType.label"
                  variant="outline-primary"
                >
                  <b-dropdown-item
                    v-for="(item, index) in timeTypeOptions"
                    :key="index"
                    @click="timeTypeOptionChange(item)"
                  >
                    {{ item.label }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-input-group-prepend>
              <div class="time-type">
                <date-picker
                  :time-range-default="timeRangeDefault"
                  @TimeRange="getTimeRange"
                />
              </div>
            </b-input-group>
          </b-col>
          <!-- <b-col md="5">
            <label
              class="mr-sm-1"
              for="inline-form-custom-select-pref"
            >CreateTime:</label>
            <date-picker
              :time-range-default="timeRangeDefault"
              @TimeRange="getTimeRange"
            />
          </b-col>
          <b-col md="5">
            <label
              class="mr-sm-1"
              for="inline-form-custom-select-pref"
            >UpdateTime:</label>
            <date-picker
              :time-range-default="timeUpdateDefault"
              @TimeRange="getTimeUpdate"
            />
          </b-col> -->
          <b-col md="4">
            <label
              class="mr-sm-1"
              for="inline-form-custom-select-pref"
            >Currency:</label>
            <v-select
              v-model="currency"
              :options="currencyOptions"
              class="invoice-filter-select mb-1 mr-sm-1 no-clear-select"
              placeholder="Currency"
              label="name"
            />
          </b-col>
          <!-- <b-col md="4">
            <label
              class="mr-sm-1"
              for="inline-form-custom-select-pref"
            >TransactionId:</label>
            <b-form-input
              v-model="transactionId"
              class="d-inline-block mr-1 mb-1"
              placeholder="TransactionId"
              autocomplete="off"
            />
          </b-col> -->
        </b-row>
        <b-row>
          <!-- <b-col md="4">
            <label for="inline-form-custom-select-pref">Merchant Search:</label>
            <b-input-group>
              <b-input-group-prepend>
                <b-dropdown
                  :text="merchantType.label"
                  variant="outline-primary"
                >
                  <b-dropdown-item
                    v-for="(item, index) in merchantOptions"
                    :key="index"
                    @click="merchantTypeOptionChange(item)"
                  >
                    {{ item.label }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-input-group-prepend>
              <b-form-input
                v-model="merchantSearchKey"
                placeholder="Search..."
                autocomplete="off"
              />
            </b-input-group>
          </b-col> -->
          <b-col md="6">
            <label for="inline-form-custom-select-pref">User Search:</label>
            <b-input-group>
              <b-input-group-prepend>
                <b-dropdown
                  :text="userType.label"
                  variant="outline-primary"
                >
                  <b-dropdown-item
                    v-for="(item, index) in userOptions"
                    :key="index"
                    @click="userTypeOptionChange(item)"
                  >
                    {{ item.label }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-input-group-prepend>
              <b-form-input
                v-model="userSearchKey"
                placeholder="Search..."
                autocomplete="off"
              />
            </b-input-group>
          </b-col>
          <b-col md="6">
            <label for="inline-form-custom-select-pref">Order Search:</label>
            <b-input-group>
              <b-input-group-prepend>
                <b-dropdown
                  :text="orderType.label"
                  variant="outline-primary"
                >
                  <b-dropdown-item
                    v-for="(item, index) in orderOptions"
                    :key="index"
                    @click="orderTypeOptionChange(item)"
                  >
                    {{ item.label }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-input-group-prepend>
              <b-form-input
                v-model="orderSearchKey"
                placeholder="Search..."
                autocomplete="off"
              />
            </b-input-group>
          </b-col>
        </b-row>
        <!-- <b-row>
          <b-col md="6">
            <label
              class="mr-sm-1"
              for="inline-form-custom-select-pref"
            >Initiator Name:</label>
            <div class="transfer-flex-node">
              <v-select
                v-model="initiatorMode"
                :options="modeOptions"
                class="invoice-filter-select mb-1 mr-sm-1 no-clear-select"
                placeholder="Transaction ID"
                label="title"
                style="width: 190px"
              />
              <b-form-input
                v-model="initiatorKey"
                class="d-inline-block mr-1 mb-1"
                placeholder="Initiator Name"
                autocomplete="off"
                style="flex: 1"
              />
            </div>
          </b-col>
          <b-col md="6">
            <label
              class="mr-sm-1"
              for="inline-form-custom-select-pref"
            >Payee Name:</label>
            <div class="transfer-flex-node">
              <v-select
                v-model="payeeMode"
                :options="modeOptions"
                class="invoice-filter-select mb-1 mr-sm-1 no-clear-select"
                placeholder="Transaction ID"
                label="title"
                style="width: 190px"
              />
              <b-form-input
                v-model="payeeKey"
                class="d-inline-block mr-1 mb-1"
                placeholder="Payee Name"
                autocomplete="off"
              />
            </div>
          </b-col>
        </b-row> -->
        <b-row>
          <b-col class="text-center">
            <b-button
              variant="primary"
              class="mr-1 b-col-top"
              @click="search"
            >Search</b-button>
            <b-button
              variant="secondary"
              class="mr-1 b-col-top"
              @click="reset"
            >Reset</b-button>
            <b-button
              variant="success"
              class="b-col-top"
              @click="download"
            >Download</b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <b-table
      ref="refTransferListTable"
      :items="purchaseList"
      responsive
      :fields="tableColumns"
      primary-key="transactionId"
      show-empty
      empty-text="No matching records found"
      class="position-relative withdraw-table"
    >
      <!-- Column: transactionId -->
      <template #cell(transaction_id)="data">
        <b-link
          :to="{
            path: '/purchases/purchases-detail',
            query: { id: data.item.transaction_id, timezone: timeZone },
          }"
          class="font-weight-bold"
          target="_blank"
        >
          {{ data.value }}
        </b-link>
      </template>
      <!-- purchaseStatusArray -->
      <!-- Column: Issued createdAt -->
      <template #cell(transaction_status)="data">
        <span class="text-nowrap">
          {{ purchaseStatusArray[data.value] }}
        </span>
      </template>
      <!-- Column: user_id -->
      <template #cell(user_id)="data">
        <b-link
          :to="{
            path: '/usercenter/user-detail',
            query: { id: data.item.user_id, timezone: timeZone },
          }"
          class="font-weight-bold"
          target="_blank"
        >
          {{ data.value }}
        </b-link>
      </template>
      <!-- Column: Issued createdAt -->
      <template #cell(created_time)="data">
        <span class="text-nowrap">
          {{ updateDate(data.value, timeZone) }}
        </span>
      </template>
      <!-- Column: Issued updatedAt -->
      <template #cell(updated_time)="data">
        <span class="text-nowrap">
          {{ updateDate(data.value, timeZone) }}
        </span>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span
            class="text-muted mr-1"
          >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
            {{ dataMeta.of }} entries</span>
          <span
            class="text-muted"
          >Total Amount {{ dataMeta.totalAmount }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="changePagination"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BForm,
  BFormSelect,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted, ref } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex'
import { updateDate, setDatetimeDefault, handlerTimestamp } from '@/libs/utils'
import store from '@/store'
import DatePicker from '@/component/Datepicker.vue'
import usePurchases from './usePurchases'
import purchasesStoreModule from './purchasesStoreModule'

const { mapState: mapStateWallet } = createNamespacedHelpers('wallet')

export default {
  components: {
    BForm,
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BInputGroup,
    BInputGroupPrepend,
    vSelect,
    DatePicker,
  },
  data() {
    return {}
  },
  computed: {
    ...mapStateWallet(['timeZone', 'currencys']),
    currencyOptions() {
      return this.currencys
    },
  },
  watch: {
    timeZone() {
    //  this.timezone = value
    //  this.timeRange = []
    //  // this.timeRange = handlerTimestamp(
    //  //   this.timeRangeDefault[0],
    //  //   this.timeRangeDefault[1],
    //  //   value,
    //  // )
    //  this.fetchTransferList()
    },
  },
  mounted() {
    this.timezone = this.timeZone
  },
  methods: {
    search() {
      this.currentPage = 1
      this.fetchTransferList()
    },
    reset() {
      this.purchaseStatus = { title: 'All', value: '-1' }
      this.timeType = { label: 'CREATE', value: 'create' }
      this.timeRange = []
      this.timeRangeDefault = []
      this.currency = { code: 'BRL', name: 'BRL' }
      this.merchantType = { label: 'MERCHANT ID', value: '8' }
      this.merchantSearchKey = ''
      this.userType = { label: 'USER ID', value: '3' }
      this.userSearchKey = ''
      this.orderType = { label: 'TRANSACTION ID', value: '1' }
      this.orderSearchKey = ''
      // this.fetchTransferList()
    },
    download() {
      this.fetchTransferList(true, () => {
        this.$router.push({ name: 'Download' })
      })
    },
    changePagination(page) {
      this.currentPage = page
      this.fetchTransferList()
    },
    getTimeRange(time) {
      this.timeRange = time
    },
  },
  setup() {
    const PURCHASES_APP_STORE_MODULE_NAME = 'purchases'
    // Register module
    if (!store.hasModule(PURCHASES_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        PURCHASES_APP_STORE_MODULE_NAME,
        purchasesStoreModule,
      )
    }
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PURCHASES_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PURCHASES_APP_STORE_MODULE_NAME)
      }
    })
    // 多选项
    // 搜索模式 1 - transaction_id, 2 - channel_id(thirdparty_id/partner_id), 3 - uuid, 4 -phone, 5 - document_id, 6 - email, 7 - name， 8 - merchant_id
    const modeOptions = ref([
      { title: 'Phone', value: '4' },
      { title: 'DocumentId', value: '5' },
    ])
    const timeTypeOptions = ref([
      { label: 'CREATE', value: 'create' },
      { label: 'UPDATE', value: 'update' },
    ])
    // 搜索模式 1 - transaction_id, 2 - channel_id(thirdparty_id/partner_id), 3 - uuid, 4 -phone, 5 - document_id, 6 - email, 7 - name， 8 - merchant_id
    const merchantOptions = ref([
      { label: 'MERCHANT ID', value: '8' },
      { label: 'EMAIL', value: '6' },
    ])
    // 搜索模式 1 - transaction_id, 2 - channel_id(thirdparty_id/partner_id), 3 - uuid, 4 -phone, 5 - document_id, 6 - email, 7 - name， 8 - merchant_id
    const userOptions = ref([
      { label: 'USER ID', value: '3' },
      { label: 'PHONE', value: '4' },
      { label: 'DOCUMENT ID', value: '5' },
    ])
    const orderOptions = ref([
      { label: 'TRANSACTION ID', value: '1' },
      { label: 'THIRD-PARTY ID', value: '2' },
    ])
    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      refTransferListTable,

      purchaseStatusOption,
      purchaseStatusArray,

      initiatorKey,
      payeeKey,
      transactionId,
      initiatorMode,
      payeeMode,
      purchaseStatus,
      timeRange,
      timeRangeDefault,
      timezone,

      purchaseList,
      fetchTransferList,

      timeType,
      currency,
      merchantType,
      merchantSearchKey,
      userType,
      userSearchKey,
      orderType,
      orderSearchKey,
    } = usePurchases()
    const timeTypeOptionChange = item => {
      timeType.value = item
    }
    const merchantTypeOptionChange = item => {
      merchantType.value = item
    }
    const userTypeOptionChange = item => {
      userType.value = item
    }
    const orderTypeOptionChange = item => {
      orderType.value = item
    }
    return {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      refTransferListTable,
      modeOptions,
      purchaseStatusOption,
      purchaseStatusArray,

      initiatorKey,
      payeeKey,
      transactionId,
      initiatorMode,
      payeeMode,
      purchaseStatus,
      timeRange,
      timeRangeDefault,
      timezone,

      purchaseList,
      fetchTransferList,
      updateDate,

      timeTypeOptionChange,
      merchantTypeOptionChange,
      userTypeOptionChange,
      orderTypeOptionChange,

      timeType,
      currency,
      merchantType,
      merchantSearchKey,
      userType,
      userSearchKey,
      orderType,
      orderSearchKey,

      timeTypeOptions,
      merchantOptions,
      userOptions,
      orderOptions,
    }
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;
  width: 100%;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
.b-form-flex {
  display: flex;
  flex-direction: row;
}
.withdraw {
  ::v-deep .withdraw-table {
    .table tr td:nth-of-type(1) {
      word-break: break-all;
      word-wrap: break-all;
      min-width: 10rem;
    }
    .table tr td:nth-of-type(4) {
      min-width: 5rem;
    }
    .table tr td:nth-of-type(9) {
      min-width: 11rem;
    }
    .table tr td:nth-of-type(10) {
      min-width: 11rem;
    }
  }
  .b-col-top {
    margin-top: 1.67rem;
  }
  .time-type {
    flex: 1;
  }
}
.transfer-flex-node {
  display: flex;
}
</style>
