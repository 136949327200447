import {
  ref, watch, computed, onMounted,
} from '@vue/composition-api'
import Vue from 'vue'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import loadingRegister from '@/libs/loading/loading'
import store from '@/store'
import { trim, setDatetimeDefault, handlerTimestamp } from '@/libs/utils'

Vue.use(loadingRegister)

export default function useInvoicesList() {
  // Use toast
  const toast = useToast()
  const refTransferListTable = ref(null)
  // Table Handlers
  const tableColumns = [
    { key: 'transaction_id', label: 'Transaction ID' },
    { key: 'product_name', label: 'Product Name' },
    { key: 'amount', label: 'Amount' },
    { key: 'currency', label: 'Currency' },
    { key: 'transaction_status_text', label: 'purchase status' },
    { key: 'user_name', label: 'user Name' },
    { key: 'user_id', label: 'user id' },
    { key: 'merchant_name', label: 'merchant Name' },
    { key: 'merchant_id', label: 'merchant id' },
    { key: 'thirdparty_id', label: 'third-party order id' },
    { key: 'thirdparty_status', label: 'third-party status' },
    { key: 'created_time', label: 'Create time' },
    { key: 'updated_time', label: 'Update time' },
  ]
  const perPage = ref(10)
  const totalInvoices = ref(0)
  const totalAmount = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]

  // 筛选项
  const purchaseStatus = ref({ title: 'All', value: '-1' })
  const timeType = ref({ label: 'CREATE', value: 'create' })
  const timeRange = ref([])
  const timeRangeDefault = ref([])
  // *默认时间*
  // timeRangeDefault.value = setDatetimeDefault(1, 1)
  // timeRange.value = handlerTimestamp(
  //   timeRangeDefault.value[0],
  //   timeRangeDefault.value[1],
  //   'America/Sao_Paulo',
  // )
  const currency = ref({ code: 'BRL', name: 'BRL' })
  const merchantType = ref({ label: 'MERCHANT ID', value: '8' })
  const merchantSearchKey = ref('')
  const userType = ref({ label: 'USER ID', value: '3' })
  const userSearchKey = ref('')
  const orderType = ref({ label: 'TRANSACTION ID', value: '1' })
  const orderSearchKey = ref('')

  const purchaseStatusOption = ref([])
  const purchaseStatusArray = ref([])
  const initiatorKey = ref(null)
  const payeeKey = ref(null)
  const transactionId = ref(null)
  const initiatorMode = ref({ title: 'Phone', value: '4' })
  const payeeMode = ref({ title: 'Phone', value: '4' })

  // const timeUpdate = ref([])
  // const timeUpdateDefault = ref([])
  // timeUpdateDefault.value = setDatetimeDefault(1, 1)
  // timeUpdate.value = handlerTimestamp(
  //   timeUpdateDefault.value[0],
  //   timeUpdateDefault.value[1],
  //   'America/Sao_Paulo',
  // )

  const purchaseList = ref([])
  const timezone = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refTransferListTable.value ? refTransferListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
      totalAmount: totalAmount.value,
    }
  })

  const refetchData = () => {
    refTransferListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  // 获取状态
  const fetchWalletStatusOption = () => {
    store
      .dispatch('purchases/fetchWalletStatus')
      .then(response => {
        if (response.data.code == 200) {
          const { data } = response.data
          purchaseStatusOption.value = handlerFormatData(data)
          purchaseStatusArray.value = data.reduce((initValue, value) => Object.assign(initValue, value))
        } else { }
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchTransferList = (isDownload, callback) => {
    Vue.prototype.$showLoading()
    // const initiatorKeyValue = initiatorKey.value ? initiatorKey.value.replace(/[^\d]/g, '') : ''
    // const payeeKeyValue = payeeKey.value ? payeeKey.value.replace(/[^\d]/g, '') : ''
    const params = {
      transaction_status: Number(purchaseStatus.value.value),
      page_no: currentPage.value,
      page_size: perPage.value,
      currency: currency.value.code,
      created_time_scope: {
        start_date: null,
        end_date: null,
      },
      updated_time_scope: {
        start_date: null,
        end_date: null,
      },
      // merchant_search: {
      //   model: Number(merchantType.value.value),
      //   key: trim(merchantSearchKey.value),
      // },
      user_search: {
        model: Number(userType.value.value),
        key: trim(userSearchKey.value),
      },
      order_search: {
        model: Number(orderType.value.value),
        key: trim(orderSearchKey.value),
      },
      download: false,
    }
    if ((params.user_search.model === 4 || params.user_search.model === 5) && params.user_search.key) { // 4为phone 5为documentid
      params.user_search.key = params.user_search.key.replace(/[^\d]/g, '') // 过滤后剩下数字
    }
    if (timeRange.value.length > 0) {
      if (timeType.value.value === 'create') {
        [params.created_time_scope.start_date, params.created_time_scope.end_date] = timeRange.value
        params.updated_time_scope.start_date = null
        params.updated_time_scope.end_date = null
      } else if (timeType.value.value === 'update') {
        [params.updated_time_scope.start_date, params.updated_time_scope.end_date] = timeRange.value
        params.created_time_scope.start_date = null
        params.created_time_scope.end_date = null
      }
    }
    if (isDownload) {
      params.download = true
    }
    store.dispatch('purchases/fetchPurchaseList', params).then(response => {
      Vue.prototype.$hideLoading()
      if (response.data.code === 200 && response.data.data) {
        const { list, pagination, ext_data } = response.data.data
        purchaseList.value = list
        totalInvoices.value = pagination.total_count
        // eslint-disable-next-line camelcase
        totalAmount.value = ext_data ? ext_data.total_amount : 0
        if (callback) {
          callback()
        }
      } else if (response.data.code === 200 && !response.data.data) {
        purchaseList.value = []
        totalInvoices.value = 0
        if (callback) {
          callback()
        }
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    }).catch(error => {
      Vue.prototype.$hideLoading()
      toast({
        component: ToastificationContent,
        props: {
          title: error.message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }

  const handlerFormatData = data => {
    if (!data) return
    const options = []
    data.forEach(obj => {
      const o = {}
      o.value = Object.keys(obj)[0]
      o.title = obj[o.value]
      options.push(o)
    })
    return options
  }

  fetchWalletStatusOption()

  return {
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    refTransferListTable,

    initiatorKey,
    payeeKey,
    transactionId,
    purchaseStatusOption,
    purchaseStatusArray,

    initiatorMode,
    payeeMode,
    purchaseStatus,
    timeRange,
    timeRangeDefault,
    timezone,

    purchaseList,
    fetchTransferList,

    // 筛选项
    timeType,
    currency,
    merchantType,
    merchantSearchKey,
    userType,
    userSearchKey,
    orderType,
    orderSearchKey,
  }
}
